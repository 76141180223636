<template>
	<el-row :gutter="160">
		<el-col :span="24" style="text-align: left;">
			<el-button type="primary" icon="el-icon-plus" @click="onClickAdd">添加克隆服配置</el-button>
            <el-button type="success" icon="el-icon-mobile-phone" @click="onClickAddPcid">设置克隆角色id</el-button>
            <el-button type="danger" icon="el-icon-brush" @click="onClickResetSlaveRedis">清理缓存脏数据</el-button>            
		</el-col>
		<el-col :span="24">
			<el-table ref="multipleTable" :data="paramList" :height="tableHeight" border style="width: 100%" :row-class-name="tableRowClassName"
			:cell-style="{padding:4+'px'}" class="serverlist">
                <el-table-column prop="id" label="id号" width="90"> </el-table-column>
                <el-table-column prop="serverid" label="服务器id号" show-overflow-tooltip> </el-table-column>
                <el-table-column prop="name" label="servername" show-overflow-tooltip> </el-table-column>
                <el-table-column prop="title" label="显示名称" show-overflow-tooltip></el-table-column>
                <el-table-column prop="redis" label="redis端口" show-overflow-tooltip></el-table-column>
                <el-table-column prop="create_time" label="创建时间" show-overflow-tooltip></el-table-column>
                <el-table-column label="操作" width="300">
                    <template slot-scope="scope">
                        <el-button @click.native.prevent="onClickActiveClone(scope.row)" :type="enabledButton(scope.row)" icon="el-icon-video-play" size="small" :disabled="1==scope.row.enabled">{{scope.row.enabled?"已激活":"切激活"}}</el-button>
                        <el-button @click.native.prevent="onClickEditParam(scope.row)" type="primary" icon="el-icon-edit" size="small">修改</el-button>
                        <el-button @click.native.prevent="onClickDelParam(scope.row)" type="danger" icon="el-icon-delete" size="small">删除</el-button>
                    </template>
                </el-table-column>
			</el-table>
		</el-col>
		<!-- ========弹框BEGIN====== -->
		<el-dialog :title="formTitle" :visible.sync="dialogForm" width="40%" :close-on-click-modal="false" center class="formparams">
			<el-form :model="form">
                <el-form-item label="区服列表" :label-width="formLabelWidth">
                    <el-select v-model="form.group" placeholder="选择区服列表" value-key="id" @change="onFormSelectGroup" style="width: 100%;">
                        <el-option v-for="item in serverGroups" :key="item.id" :label="item.name" :value="item">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="区服" :label-width="formLabelWidth">
                    <el-select
                    v-model="form.serverid"
                    placeholder="指定区服" style="width: 100%;">
                        <el-option v-for="item in formServers" :key="item.id" :label="item.title" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="redis端口" :label-width="formLabelWidth">
                    <el-input v-model="form.redis" autocomplete="off" @change="onFormEdited"></el-input>
                </el-form-item>  
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" icon="el-icon-check" @click="onAddCloneServer">确 定</el-button>
				<el-button icon="el-icon-close" @click="dialogForm = false">取 消</el-button>
			</div>
		</el-dialog>
		<!-- ========弹框END====== -->
		<!-- ========弹框PCID BEGIN====== -->
		<el-dialog title="当前克隆PCID" :visible.sync="dialogFormPcid" width="40%" :close-on-click-modal="false" center class="formparams">
			<el-form :model="form">
                <el-form-item label="角色pcid" :label-width="formLabelWidth">
                    <el-input v-model="form.pcid" autocomplete="off"  placeholder="角色id"></el-input>
                </el-form-item>  
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" icon="el-icon-check" @click="onAddClonePcid">确 定</el-button>
				<el-button icon="el-icon-close" @click="dialogFormPcid = false">取 消</el-button>
			</div>
		</el-dialog>
		<!-- ========弹框END====== -->
        <!-- ========分组管理弹框END====== -->
        <el-dialog title="提示" :visible.sync="dialogLoading" width="24%" :close-on-click-modal="false" :show-close="false">
          <span>操作中，请等待...</span>
        </el-dialog>
	</el-row>
</template>

<script>
	import {
		mapGetters
	} from 'vuex'

	export default {
		props: ['pkgtype'],
		data() {
			return {
				paramList: [],
                formTitle: '',
				dialogForm: false,
                dialogFormPcid: false,
                form: {
                    id: '',
                    group: '',
                    serverid: '',
                    redis: '',
                    pcid: '',
                },
				noneChanged: true,
                formLabelWidth: '130px',
                serverGroups: [],
                formServers: [],
                dialogLoading:false,
                tableHeight: document.documentElement.clientHeight-136,
			}
		},
		created: function() {
            this.refreshGroupList()
			this.refreshOnlineParamList()
		},
		computed: {
			...mapGetters(
				['gameHttp']
			),
		},
		methods: {
			// 刷新列表
			refreshOnlineParamList() {
				this.gameHttp(2201, {}).then((response) => {
					this.paramList = response.data.list
				})
			},
            // 刷新分组列表
            refreshGroupList() {
                this.gameHttp(2006, {
                    plain: 1
                }).then((response) => {
                    this.serverGroups = response.data.list
                    this.serverGroups.splice(0, 0, {
                        id: -1,
                        name: "全部区服列表"
                    })
                })
            },
            // 表单选择分组
            onFormSelectGroup(item) {
                this.gameHttp(2009, { // 拉取服务器列表
                    listid: item.id,
                }).then((response) => {
                    this.formServers = response.data.list
                })
            },
			// 弹出新增参数编辑框
			onClickAdd() {
                this.formTitle = '添加克隆服'
				this.dialogForm = true
                this.noneChanged = true
                for (let k in this.form) {
                    this.form[k] = ''
                }
			},
			// 弹出克隆pcid编辑框
			onClickAddPcid() {
				this.dialogFormPcid = true
                // 请求当前pcid
                this.gameHttp(2205, {op:"GET"}).then((response) => {
                    if (response.data.errcode==0) {
                        this.form.pcid = response.data.pcid;
                    }
                })
			},
            // 弹出确认框重置slave redis
            onClickResetSlaveRedis() {
                this.$confirm('是否确定执行【清理缓存脏数据】，执行后请等待5分钟后再使用', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.dialogLoading = true;
                    this.gameHttp(2206, {}).then((response) => {
                        if (0 == response.data.errcode) {
                            this.dialogLoading = false;
                            this.$message.success("执行成功");
                        }
                    })
                }).catch(() => {})
            },
            // 弹出修改参数编辑框
            onClickEditParam(row) {
                this.formTitle = '修改克隆服'
				this.dialogForm = true
                this.noneChanged = true 
                this.form = JSON.parse(JSON.stringify(row))
            },
            // 弹出删除确认框
            onClickDelParam(row) {
                this.$confirm('是否删除[ '+row.name+' ]克隆服?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.gameHttp(2203, {
                        id: row.id
                    }).then((response) => {
                        if (0 == response.data.errcode) {
                            this.$message.success("删除成功")
                            this.refreshOnlineParamList()
                        }
                    })
                }).catch(() => {})
            },            
            // 编辑框内容变化事件
            onFormEdited() {
                this.noneChanged = false
            },           
			// 添加配置
			onAddCloneServer() {
				if (0 == this.form.redis.length) {
                    this.$message.error('请指定redis端口'); return;
                }
				if (0 == this.form.serverid.length) {
                    this.$message.error('请指定克隆服'); return;
                }                
                this.dialogForm = false
                // 请求操作
                this.gameHttp(2202, this.form).then((response) => {
                    response.data.errcode==0?this.$message.success(response.data.errmsg):this.$message.error(response.data.errmsg)
                    this.refreshOnlineParamList()
                })
			},
            // 添加pcid
            onAddClonePcid() {
				if (0 == this.form.pcid.length) {
                    this.$message.error('请指定克隆角色id'); return;
                }                
                this.dialogFormPcid = false
                // 请求操作
                this.gameHttp(2205, {op:"ADD",pcid:this.form.pcid}).then((response) => {
                    response.data.errcode==0?this.$message.success(response.data.errmsg):this.$message.error(response.data.errmsg)
                    this.form.pcid = response.data.pcid;
                })                
            },
            // 切换激活克隆服
            onClickActiveClone(row) {
                this.$confirm('是否将激活克隆服切换至[ '+row.title+' ]', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.dialogLoading = true;
                    this.gameHttp(2204, {
                        id: row.id
                    }).then((response) => {
                        this.dialogLoading = false;
                        if (0 == response.data.errcode) {
                            this.$message.success("操作成功，等待游戏服重启成功即可进入");
                            this.refreshOnlineParamList();
                        } else {
                            this.$message.error(response.data.errmsg);
                        }
                    })
                }).catch(() => {}); 
            },
            // 激活按钮颜色
            enabledButton(row) {
                return 1==row.enabled ? 'danger':'success';
            },
			// 数据表色彩间隔效果     
			tableRowClassName({
				rowIndex
			}) {
				if (0 == rowIndex % 2) {
					return 'warning-row';
				} else {
					return 'success-row';
				}
			},
		},
	}
</script>

<style>
	.serverlist.el-table .warning-row {
		background: #f5f7fa;
	}

	.serverlist.el-table .success-row {
		background: #ffffff;
	}

	.serverlist.el-table {
		margin-top: 16px;
	}

	.serverlist {
		font-size: 12px;
	} 
</style>
